.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 19999;
  font-size: 1rem;
  color: rgb(75, 192, 192);
  /*
      background-image: url(../images/loading.gif);
      background-repeat: no-repeat;
  */
  background-color: rgb(255, 255, 255);
  background-position: 50% calc(50% - 91px);
}
.loader .container {
  display: table;
  height: 100vh;
}
.loader .container .row {
  display: table-cell;
  vertical-align: middle;
  height: 100vh;
  padding-bottom:50px;
}
.loader .col-lg-12 {
  padding: 0 3em 0;
}
.loader p.desktopVer {
  display: none;
}
.loader p.mobileVer {
  display: block;
}

@media (min-width: 768px) {
  .loader {
    background-position: center;
  }
  .loader p.desktopVer {
    display: block;
  }
  .loader p.mobileVer {
    display: none;
  }
}
