@import "loader";

html {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  font-family: arial, sans-serif
}

.wrapper-out {
  height: 100vh;
  background-color: #222;
  overflow: hidden;
  margin: 0;
}

.wrapper {
  position: relative;
  top: 100%;
  left: 50%;
  width: 400px;
  margin-left: -200px;
  font: 300 30px/1 'Open Sans Condensed', sans-serif;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  animation: 60s credits linear infinite;
}

.movie {
  margin-bottom: 50px;
  font-size: 50px;
}

.job {
  margin-bottom: 5px;
  font-size: 18px;
}

.name {
  margin-bottom: 50px;
  font-size: 35px;
}

@keyframes credits {
  0% {
    top: 100%;
  }
  100% {
    top: -500%;
  }
}

//
.copyright {
  text-align: center;
  color: #2e2e2e;
  font-size: 14px;
  letter-spacing: 1.4px;
  margin-top: 30px;
}


.count__number {
  font-size: 4rem;
  color: rgb(75, 192, 192);

}


//

.night_market_section {
  margin-top: 50px;
  height: 150px;
}

.night_market_section span {
  color: rgb(75, 192, 192);
  font-size: 3.5rem;
}

.night_market_section small {
  font-size: 0.5rem;
  color: rgb(134, 134, 134);
}

header {
  height: 3rem;
  margin: 20px;
}

#totalSection {
  background-color: rgba(255,255,255,0.8);
  border-radius:5px;
  box-shadow: 3px 1px 16px rgba(0, 0, 0, 0.1);
  font-size: 2rem;
  font-weight: bold;
}

@media all and (max-width: 410px) {
  #totalSection {
    text-shadow: 2px 1px 8px rgba(255, 255, 255, 0.94);
    font-size: 1.5rem;
    font-weight: bold;
  }
}

#logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
}

.text-end {
  display: flex;
  align-items: center;
  padding: 10px;
  flex-direction: row-reverse;
  fill: white;
}

.text-primary {
  color: rgb(75, 192, 192) !important
}

.taiwan-map, .shop-list {
  width: 50%;
  height: 100%
}

#map {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  height: 500px
}

#map svg {
  max-height: 100vh
}

#map path {
  fill: transparent;
  stroke: rgba(131, 124, 124, 0.75);
  cursor: pointer;
  -webkit-transition: fill .2s ease, stroke .2s ease, -webkit-transform .2s ease;
  transition: fill .2s ease, stroke .2s ease, -webkit-transform .2s ease;
  transition: fill .2s ease, stroke .2s ease, transform .2s ease;
  transition: fill .2s ease, stroke .2s ease, transform .2s ease, -webkit-transform .2s ease
}

#map path:hover, #map path.active {
  fill: rgba(75, 192, 192, 0.5);
  stroke: rgb(75, 192, 192);
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px)
}

h1, h2 {
  position: relative;
  line-height: 2;
  text-align: center;
  font-weight: bold
}

h1 {
  font-size: 5vw;
  color: rgb(75, 192, 192)
}

h1::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 80%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), color-stop(30%, rgba(255, 255, 255, 0.8)), color-stop(50%, rgba(255, 255, 255, 0.8)), color-stop(70%, rgba(255, 255, 255, 0.8)), to(rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 30%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.8) 70%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 30%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.8) 70%, rgba(255, 255, 255, 0) 100%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%)
}

h2 {
  font-size: 4vw;
  color: rgba(255, 255, 255, 0.8)
}

.shop-list {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap
}

.shop-list h1, .shop-list h2 {
  width: 100%
}

.videoBg {

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -99;
}

@media (min-aspect-ratio: 16/9) {
  .videoBg {
    width: 100%;
    height: 100vh;
  }
}

@media (max-aspect-ratio: 16/9) {
  .videoBg {
    width: 100%;
    height: 100vh;
    padding: 0
  }
}

video {
  object-fit: cover;

}

// 謝謝區塊
.wrapper-out {
  min-height: 100vh;
  overflow: hidden;
  margin: 0;
  background-color: rgba(0, 0, 0, .1);
  background-position: center;
  background-image: url("../images/thank-ok.jpg");
  background-size: cover;
}

.page {
  min-height:100vh;
  align-items: center;
  padding: 5%;
}

.header {
  display: flex;
  align-content: center;
  margin: 0;
  height: 70px;
  background-color: #4BC0C0;
  -webkit-box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.24);
}
